
//
//  Buttons
//  ---------------------------------------------

.btn(
    @button__background: @button-default__background,
    @button__color: @button-default__color,
    @button__background--hover: @button-default__background--hover,
    @button__color--hover: @button-default__color--hover,
    @button__border: @button-default__border,
    @button__border--hover: @button-default__border--hover
) {

    // Default styling
    .lib-css(padding, @button__padding);
    .lib-css(border-radius, @button__border-radius);
    .lib-css(font-family, @button__font-family);
    .lib-css(font-weight, @button__font-weight);
    .lib-css(font-size, @button__font-size);
    .lib-css(cursor, pointer);
    .lib-css(align-items, center);
    .lib-css(justify-content, center);
    .lib-css(gap, @button__gap);

    .lib-css(background, @button__background);
    .lib-css(color, @button__color);
    .lib-css(text-align, @button__text-align);
    .lib-css(text-decoration, @button__text-decoration);
    .lib-css(border, @button__border);

    &:visited {
        .lib-css(background, @button__background);
        .lib-css(color, @button__color);
        .lib-css(text-decoration, @button__text-decoration);
        .lib-css(border, @button__border);
    }

    &:hover,
    &:focus,
    &:focus-visible,
    &:active {
        .lib-css(background, @button__background--hover);
        .lib-css(color, @button__color--hover);
        .lib-css(text-decoration, @button__text-decoration--hover);
        .lib-css(border, @button__border--hover);
    }

    &:focus-visible {
        outline: revert;
    }

    // Reset styling
    .btn-reset();

    &:visited,
    &:focus,
    &:hover,
    &:active {
        .btn-reset();
    }

    @media (max-width: 767px) {
        .lib-css(padding, @button__padding-mobile);
        .lib-css(font-size, @button__font-size-mobile);
    }
}

.btn-reset() {
    box-sizing: border-box;
    box-shadow: none;
    line-height: @button__line-height;
    display: inline-flex;
}

.btn-primary() {
    .btn(
        @button__background: @button-primary__background,
        @button__color: @button-primary__color,
        @button__background--hover: @button-primary__background--hover,
        @button__color--hover: @button-primary__color--hover,
        @button__border: @button-primary__border,
        @button__border--hover: @button-primary__border--hover
    )
}

.btn-secondary() {
    .btn(
        @button__background: @button-secondary__background,
        @button__color: @button-secondary__color,
        @button__background--hover: @button-secondary__background--hover,
        @button__color--hover: @button-secondary__color--hover,
        @button__border: @button-secondary__border,
        @button__border--hover: @button-secondary__border--hover
    )
}

.btn-alt() {
    .btn(
        @button__background: @button-alt__background,
        @button__color: @button-alt__color,
        @button__background--hover: @button-alt__background--hover,
        @button__color--hover: @button-alt__color--hover,
        @button__border: @button-alt__border,
        @button__border--hover: @button-alt__border--hover
    )
}

.btn-diap() {
    .btn(
        @button__background: @button-diap__background,
        @button__color: @button-diap__color,
        @button__background--hover: @button-diap__background--hover,
        @button__color--hover: @button-diap__color--hover,
        @button__border: @button-diap__border,
        @button__border--hover: @button-diap__border--hover
    )
}

.btn-square() {
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1/1;
    margin: 0;
    padding: 0;
    width: 56px;

    @media (max-width: 767px) {
        width: 48px;
        padding: 0;
    }
}

.btn-cart() {
    .btn-primary();

    &::after {
        content: '';
        .svg-cart-add-icon-white();
        .svg-cart-add-icon-white-dims();
        display: block;
        flex: 0 0 auto;
    }
}

.btn-cart-secondary() {
    .btn-secondary();

    &::after {
        content: '';
        .svg-cart-add-icon-white();
        .svg-cart-add-icon-white-dims();
        display: block;
        flex: 0 0 auto;
    }

    &:hover {
        &::after {
            .svg-cart-add-icon-blue();
            .svg-cart-add-icon-blue-dims();
        }
    }
}

.btn-responsive() {
    width: 100%;
}
