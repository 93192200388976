@media screen and (max-width: 768px) {
    .category-header-block {
        .frame {
            &:before,
            &:after {
                display: none;
            }
        }
        [data-content-type="row"],
        .category-header-images {
            display: none;
        }
    }
}
@media screen and (min-width: 769px) {
    .category-header-block {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        margin-bottom: 72px;
        align-items: center;
        border-radius: 8px;
    }

    .category-header-text {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        flex-direction: column;
        grid-column: span 1;
        height: 100%;
        background: white;
        border-radius: 8px;
        padding: 40px;
        box-shadow: 0 4px 8px 0 rgba(13, 49, 77, .04),
        0 14px 14px 0 rgba(13, 49, 77, .04),
        0 32px 19px 0 rgba(13, 49, 77, .02),
        0 57px 23px 0 rgba(13, 49, 77, .01),
        0 88px 25px 0 rgba(13, 49, 77, 0);

        h1 {
            font-size: 4.8rem;
            font-weight: 500;
            margin: 0 0 4px;
        }

        p {
            font-size: 2rem;
        }

        [data-content-type="row"] {
            padding: 0;
            margin: 0 !important;

            p:last-child {
                margin: 0;
            }
        }
    }

    .category-header-images {
        display: flex;
        gap: 10px;
        grid-column: span 1;
        justify-content: flex-start;
    }

    .category-header-images .frame {
        height: 230px;

        &:first-child {
            width: ~'calc(100% / 3)';
        }

        &:last-child {
            width: ~'calc((100% / 3) * 2)';
        }

        img {
            max-width: 100%;
            height: 100%;
            width: 100%;
            object-fit: contain;
            border-radius: 8px;
        }
    }
}
