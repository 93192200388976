
//
//  Frame
//  ---------------------------------------------

.lib-frame(
    @border-width: 0px
) {
    overflow: visible;
    position: relative;

    &::before,
    &::after {
        content: '';
        width: ~"calc(100% + (2 * @{border-width}))";
        height: ~"calc(100% + (2 * @{border-width}))";
        position: absolute;
        left: ~"calc(-1 * @{border-width})";
        top: ~"calc(-1 * @{border-width})";
        pointer-events: none;
        border-width: 1px;
        border-style: solid;
        box-sizing: border-box;
    }

    &::before {
        width: ~"calc(100% + 140px + (2 * @{border-width}))";
        left: ~"calc(-70px - @{border-width})";
        border-image: linear-gradient(90deg, rgba(185,196,205,0) 0%, rgba(185,196,205,.6) ~"calc(70px + @{border-width})", rgba(185,196,205,.6) ~"calc(100% - (70px + @{border-width}))", rgba(185,196,205,0) 100%) 1;
        border-left: 0;
        border-right: 0;
    }

    &::after {
        height: ~"calc(100% + 140px + (2 * @{border-width}))";
        top: ~"calc(-70px - @{border-width})";
        border-image:
            linear-gradient(to bottom, rgba(185,196,205,0) 0%, rgba(185,196,205,.6) ~"calc(70px + @{border-width})", rgba(185,196,205,.6) ~"calc(100% - (70px + @{border-width}))", rgba(185,196,205,0) 100%) 1 100%;
        border-top: 0;
        border-bottom: 0;
    }

    &--hover {
        &:hover {
            &::before,
            &::after {
                z-index: -1;
            }
        }
    }
}
