//
//  Generic
//  ---------------------------------------------

* {
    box-sizing: border-box;
}

body {
    -webkit-font-smoothing: antialiased;
    background-color: @tertiary__color;
    overflow-x: hidden;

    &.navigation--open {
        overflow-y: hidden;
    }
}

.frame {
    .lib-frame();
}

.shadow {
    box-shadow: @default__box-shadow;
}

.shadow-small {
    box-shadow: @default__box-shadow--small;
}

.arrow-title {
    display: flex;
    justify-content: space-between;
    gap: 8px;

    &::after {
        content: '';
        .svg-arrow-blue-icon();
        .svg-arrow-blue-icon-dims();
        flex-shrink: 0;
    }
}
