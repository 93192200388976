.svg-common() {
	background: url("../svg/dist/css/css-sprite.svg") no-repeat;
}

.svg-account-icon() {
.svg-common();
background-position: 0 0;
}

.svg-account-icon-dims() {
	width: 24px;
height: 24px;
}

.svg-arrow-blue-icon() {
.svg-common();
background-position: 0 2.9375764993880047%;
}

.svg-arrow-blue-icon-dims() {
	width: 24px;
height: 24px;
}

.svg-arrow-down-icon() {
.svg-common();
background-position: 0 5.853658536585366%;
}

.svg-arrow-down-icon-dims() {
	width: 18px;
height: 21px;
}

.svg-arrow-down-icon-blue() {
.svg-common();
background-position: 0 8.414634146341463%;
}

.svg-arrow-down-icon-blue-dims() {
	width: 18px;
height: 21px;
}

.svg-arrow-icon() {
.svg-common();
background-position: 0 10.79136690647482%;
}

.svg-arrow-icon-dims() {
	width: 10px;
height: 7px;
}

.svg-arrow-next-icon() {
.svg-common();
background-position: 0 11.700844390832328%;
}

.svg-arrow-next-icon-dims() {
	width: 14px;
height: 12px;
}

.svg-arrow-next-icon-blue() {
.svg-common();
background-position: 0 13.148371531966225%;
}

.svg-arrow-next-icon-blue-dims() {
	width: 14px;
height: 12px;
}

.svg-arrow-right-icon-blue() {
.svg-common();
background-position: 0 14.525810324129651%;
}

.svg-arrow-right-icon-blue-dims() {
	width: 6px;
height: 8px;
}

.svg-arrow-white-icon() {
.svg-common();
background-position: 0 15.789473684210526%;
}

.svg-arrow-white-icon-dims() {
	width: 24px;
height: 24px;
}

.svg-cart-add-icon-blue() {
.svg-common();
background-position: 0 18.727050183598532%;
}

.svg-cart-add-icon-blue-dims() {
	width: 24px;
height: 24px;
}

.svg-cart-add-icon-white() {
.svg-common();
background-position: 0 21.664626682986537%;
}

.svg-cart-add-icon-white-dims() {
	width: 24px;
height: 24px;
}

.svg-cart-icon() {
.svg-common();
background-position: 0 24.60220318237454%;
}

.svg-cart-icon-dims() {
	width: 24px;
height: 24px;
}

.svg-cart-icon-white() {
.svg-common();
background-position: 0 27.47252747252747%;
}

.svg-cart-icon-white-dims() {
	width: 34px;
height: 22px;
}

.svg-check-icon-blue() {
.svg-common();
background-position: 0 29.723225030084237%;
}

.svg-check-icon-blue-dims() {
	width: 13px;
height: 10px;
}

.svg-check-icon-green() {
.svg-common();
background-position: 0 30.926594464500603%;
}

.svg-check-icon-green-dims() {
	width: 13px;
height: 10px;
}

.svg-check-icon-white() {
.svg-common();
background-position: 0 32.20747889022919%;
}

.svg-check-icon-white-dims() {
	width: 12px;
height: 12px;
}

.svg-chevron-left() {
.svg-common();
background-position: 0 34.31734317343174%;
}

.svg-chevron-left-dims() {
	width: 17px;
height: 28px;
}

.svg-chevron-left-blue() {
.svg-common();
background-position: 0 37.76137761377614%;
}

.svg-chevron-left-blue-dims() {
	width: 17px;
height: 28px;
}

.svg-chevron-right() {
.svg-common();
background-position: 0 41.20541205412054%;
}

.svg-chevron-right-dims() {
	width: 17px;
height: 28px;
}

.svg-chevron-right-blue() {
.svg-common();
background-position: 0 44.64944649446495%;
}

.svg-chevron-right-blue-dims() {
	width: 17px;
height: 28px;
}

.svg-compare-icon() {
.svg-common();
background-position: 0 47.39393939393939%;
}

.svg-compare-icon-dims() {
	width: 21px;
height: 16px;
}

.svg-compare-icon-lightblue() {
.svg-common();
background-position: 0 49.333333333333336%;
}

.svg-compare-icon-lightblue-dims() {
	width: 21px;
height: 16px;
}

.svg-cross-icon() {
.svg-common();
background-position: 0 51.774785801713584%;
}

.svg-cross-icon-dims() {
	width: 24px;
height: 24px;
}

.svg-delete-icon() {
.svg-common();
background-position: 0 54.44579780755176%;
}

.svg-delete-icon-dims() {
	width: 20px;
height: 20px;
}

.svg-delete-icon-blue() {
.svg-common();
background-position: 0 56.88185140073082%;
}

.svg-delete-icon-blue-dims() {
	width: 20px;
height: 20px;
}

.svg-delete-icon-red() {
.svg-common();
background-position: 0 58.604091456077015%;
}

.svg-delete-icon-red-dims() {
	width: 13px;
height: 10px;
}

.svg-filter() {
.svg-common();
background-position: 0 60.83231334149327%;
}

.svg-filter-dims() {
	width: 24px;
height: 24px;
}

.svg-list-icon() {
.svg-common();
background-position: 0 64.004914004914%;
}

.svg-list-icon-dims() {
	width: 19px;
height: 27px;
}

.svg-long-arrow-right-icon() {
.svg-common();
background-position: 0 65.94464500601684%;
}

.svg-long-arrow-right-icon-dims() {
	width: 12px;
height: 10px;
}

.svg-long-arrow-right-icon-lightblue() {
.svg-common();
background-position: 0 67.14801444043322%;
}

.svg-long-arrow-right-icon-lightblue-dims() {
	width: 12px;
height: 10px;
}

.svg-mail-icon() {
.svg-common();
background-position: 0 70.91136079900124%;
}

.svg-mail-icon-dims() {
	width: 40px;
height: 40px;
}

.svg-menu-icon-blue() {
.svg-common();
background-position: 0 74.05602923264311%;
}

.svg-menu-icon-blue-dims() {
	width: 28px;
height: 20px;
}

.svg-menu-icon-darkblue() {
.svg-common();
background-position: 0 76.49208282582217%;
}

.svg-menu-icon-darkblue-dims() {
	width: 28px;
height: 20px;
}

.svg-menu-icon-white() {
.svg-common();
background-position: 0 79.31456548347613%;
}

.svg-menu-icon-white-dims() {
	width: 24px;
height: 24px;
}

.svg-minus-icon() {
.svg-common();
background-position: 0 81.45454545454545%;
}

.svg-minus-icon-dims() {
	width: 16px;
height: 16px;
}

.svg-phone-icon() {
.svg-common();
background-position: 0 85.25402726146221%;
}

.svg-phone-icon-dims() {
	width: 34px;
height: 34px;
}

.svg-plus-icon() {
.svg-common();
background-position: 0 87.51515151515152%;
}

.svg-plus-icon-dims() {
	width: 16px;
height: 16px;
}

.svg-remove-icon() {
.svg-common();
background-position: 0 89.02291917973461%;
}

.svg-remove-icon-dims() {
	width: 12px;
height: 12px;
}

.svg-search-icon() {
.svg-common();
background-position: 0 92.13759213759214%;
}

.svg-search-icon-dims() {
	width: 26px;
height: 27px;
}

.svg-search-icon-white() {
.svg-common();
background-position: 0 94.87179487179488%;
}

.svg-search-icon-white-dims() {
	width: 22px;
height: 22px;
}

.svg-trash-icon() {
.svg-common();
background-position: 0 97.4390243902439%;
}

.svg-trash-icon-dims() {
	width: 18px;
height: 21px;
}

.svg-trash-icon-darkblue() {
.svg-common();
background-position: 0 100%;
}

.svg-trash-icon-darkblue-dims() {
	width: 18px;
height: 21px;
}

