
//
//  Switcher
//  ---------------------------------------------

.page-header,
.page-footer {
    .switcher {
        .options {
            .lib-bluebird-dropdown(
                @dropdown-toggle-selector: ~'.action.toggle',
                @dropdown-options-selector: ~'ul.dropdown',
                @dropdown-arrow__border-color: transparent transparent white transparent
            );

            ul.dropdown {
                li {
                    &:hover {
                        background: inherit;
                        text-decoration: underline;
                    }

                    a.switcher__link {
                        .lib-css(display, flex);
                        .lib-css(align-items, center);
                        padding: 5px;
                    }

                    .switcher__lang {
                        margin-left: @indent__s;
                    }
                }
            }
        }
    }
}

.switcher__image {
    width: 28px;
    height: 20px;
    display: inline-block;
    background-size: cover;

    vertical-align: text-bottom;
    .lib-css(border-radius, 4px);
}

.switcher-trigger {
    .switcher__image {
        margin-top: 4px;
    }
}

// 2-stores version
.page-header .switcher--one-line {
    margin-right: 0;
}

.switcher--one-line {
    .switcher__languages {
        display: flex;
    }

    .switcher__language {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: @indent__s;
        background: @color-white;
        border-radius: 0 12px 12px 0;
        border: 1px solid @quinary__color;
        border-left-color: transparent;
        padding: @indent__base / 1.5;
        transition: border-color .1s;

        .mq(m; {
            padding: @indent__s;
            border-radius: 0 8px 8px 0;
        });

        &--active {
            background: @switcher__color--active;
            border-color: @border__color--active;
            border-radius: 12px 0 0 12px;

            .mq(m; {
                border-radius: 8px 0 0 8px;
            });
        }

        &:not(.switcher__language--active):hover,
        &:not(.switcher__language--active):focus-visible {
            border-color: @border__color--focus;
        }
    }

    .switcher__lang {
        .mq(l; {
            display: none;
        });
    }

    .switcher__image {
        width: 24px;
        height: 18px;
        border-radius: 3px;
    }
}
