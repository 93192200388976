
//
//  Typography
//  ---------------------------------------------

.lib-bluebird-typography(
    @_color: @text__color,
    @_font-size: @base__font-size,
    @_font-family: @base__font-family,
    @_font-weight: @base__font-weight,
    @_line-height: @base__line-height,
    @_font-style: @base__font-style
) {
    .lib-typography(
        @_color: @_color,
        @_font-size: @_font-size,
        @_font-family: @_font-family,
        @_font-weight: @_font-weight,
        @_line-height: @_line-height,
        @_font-style: @_font-style
    );
}



.lib-bluebird-heading(
    @_heading_level: h1,
    @_color: @heading__color,
    @_font-family: @heading__font-family,
    @_font-weight: @heading__font-weight,
    @_font-style: @heading__font-style,
    @_heading-span__color: @heading-span__color
) {
    @_font-size: "@{_heading_level}__font-size";
    @_font-weight: "@{_heading_level}__font-weight";
    @_line-height: "@{_heading_level}__line-height";
    @_margin-top: "@{_heading_level}__margin-top";
    @_margin-bottom: "@{_heading_level}__margin-bottom";
    @_letter-spacing: "@{_heading_level}__letter-spacing";

    .lib-bluebird-typography(
        @_color: @_color,
        @_font-family: @_font-family,
        @_font-weight: @@_font-weight,
        @_font-style: @_font-style,
        @_line-height: @@_line-height,
        @_font-size: @@_font-size
    );

    @media screen and (min-width: 768px) {
        @_font-size: "@{_heading_level}__font-size--desktop";
        @_font-weight: "@{_heading_level}__font-weight--desktop";
        @_margin-bottom: "@{_heading_level}__margin-bottom--desktop";

        .lib-bluebird-typography(
            @_color: @_color,
            @_font-family: @_font-family,
            @_font-weight: @@_font-weight,
            @_font-style: @_font-style,
            @_line-height: @@_line-height,
            @_font-size: @@_font-size
        );

        margin-bottom: @@_margin-bottom;
    }

    margin-top: @@_margin-top;
    margin-bottom: @@_margin-bottom;
    letter-spacing: @@_letter-spacing;

    span:not(.base) {
        color: @_heading-span__color;
    }
}



.lib-unset-paragraph() {
    margin-bottom: 0;
    line-height: 0;
}
