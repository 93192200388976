//
//  Products Grid
//  ---------------------------------------------

.catalog-category-view.page-layout-2columns-left .columns .column.main {
    width: 100%;
    padding: 0;

    .mq(l; {
        position: relative;
        width: 75%;
        padding-left: 8px;
    });
}

.page-layout-1column .block.widget .products-grid .product-item:nth-child(3n + 1) {
    margin-left: 0;
}

.products-grid {
    position: relative;
    margin: 0;

    .mq(l; {
        padding: 50px 0 0;
    });

    .ias-no-more {
        display: block;
        padding: 0 15px;
    }

    .product-items:not(.widget-product-grid) {
        margin: 0;

        .mq(l; {
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin: 0;
        });


        //
        //  Product Item
        //  ---------------------------------------------

        .product-item {
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 8px;
            padding: @indent__base;
            background: @color-white;
            border-radius: 16px;
            box-shadow: 0px 4px 8px 0px rgba(13, 49, 77, 0.04),
            0px 14px 14px 0px rgba(13, 49, 77, 0.04),
            0px 32px 19px 0px rgba(13, 49, 77, 0.02),
            0px 57px 23px 0px rgba(13, 49, 77, 0.01),
            0px 88px 25px 0px rgba(13, 49, 77, 0);
            width: 100% !important;

            .mq(l; {
                width: 100%;
                padding: @indent__xl;
            });

            .mq(- xs; {
                width: 100%;
            });

            // override luma styles
            .product-item-info:not(:hover):not(.active) .product-item-inner,
            .product-item-inner {
                position: static;
                display: block;
                height: auto;
                width: auto;
                .lib-css(overflow, unset);
                .lib-css(box-shadow, none);
                .lib-css(background, none);
                .lib-css(clip, auto);
                left: auto;
                right: auto;
                z-index: auto;
                margin: 0;
                padding: 0;
                border: 0;
            }


            //
            //  Product Info
            //  ---------------------------------------------

            .product-item-link {
                font-size: 2rem;
                font-weight: 500;
                color: @primary__color;
                flex: 0 0 ~"calc(100% - 88px)";

                @media screen and (min-width: 840px) {
                    flex: 0 0 ~"calc(100% - 240px)";
                }
            }

            .product-item-info {
                width: 100%;

                // override luma styles
                &:hover,
                &.active {
                    .lib-css(box-shadow, none);
                    .lib-css(border, none);
                    margin: 0;
                    padding: 0;
                    background: transparent;
                }

                .product-item-details {
                    display: flex;
                    flex-direction: column;

                    @media screen and (min-width: 840px) {
                        flex-direction: row;
                        min-height: 240px;
                    }
                }
            }

            //
            //  Product Photo
            //  ---------------------------------------------

            .product-image-wrapper {
                margin: 0;
                z-index: 1;
            }

            .product-item-photo {
                display: flex;
                flex-wrap: wrap;
                margin: 0;
                text-align: left;
                flex: 0 0 72px;
                gap: 16px;
                color: inherit;
                position: relative;

                &:hover {
                    color: inherit;
                    text-decoration: none;
                }

                .product-image-container {
                    width: 72px !important;
                }

                @media screen and (min-width: 840px) {
                    margin: 0;
                    padding-left: calc(240px + 48px);
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    flex: 0 0 ~"calc(100% - 240px)";

                    .product-image-container {
                        flex: 0 0 240px !important;
                        width: 240px !important;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                }
            }


            //
            //  Product Name
            //  ---------------------------------------------

            .product-item-name {
                margin: 0;
                display: flex;
                flex-direction: column;
                flex-grow: 2;
            }

            .product-item-card-description {
                font-size: 2rem;
                margin-top: 24px;
                margin-bottom: 12px;

                @media screen and (max-width: 840px) {
                    height: 80px;
                    position: relative;
                    overflow: hidden;

                    &:after {
                        content: '';
                        background: ~'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 90%)';
                        height: 40px;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                    }
                }
            }

            .product-item-variants {
                font-size: 2rem;
                margin-top: auto;

                @media screen and (max-width: 839px) {
                    order: 1;
                    flex-grow: 2;
                    margin-top: 0;
                }
            }


            //
            //  Product Price
            //  ---------------------------------------------

            .price-box {
                margin: @indent__s 0;

                @media screen and (min-width: 840px) {
                    margin: 0;
                }
            }


            //
            //  Product Actions
            //  ---------------------------------------------

            .product-item-details {
                position: relative;
                display: inline-flex;
                justify-content: space-between;
                width: 100%;

                @media screen and (min-width: 840px) {
                    justify-content: stretch;
                }
            }

            .product-details {
                text-align: center;
                width: 100%;
                z-index: 10;

                &__link {
                    border: 0;
                    clip: rect(0 0 0 0);
                    height: 1px;
                    margin: -1px;
                    overflow: hidden;
                    padding: 0;
                    position: absolute;
                    width: 1px;
                    &.focusable:active,
                    &.focusable:focus {
                        clip: auto;
                        height: auto;
                        margin: 0;
                        overflow: visible;
                        position: static;
                        width: auto;
                    }
                }
            }

            .product-price-add-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                @media screen and (min-width: 840px) {
                    flex: 0 0 240px;
                    padding-left: 16px;
                }
            }

            .product-item-actions {
                margin: 0;
                z-index: 5;

                .actions-primary {
                    width: 100%;
                }

                .actions-secondary {
                    display: none;
                    width: 100%;
                    padding: @indent__s @indent__base;
                    text-align: center;
                    background: white;
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                    z-index: 5;

                    width: ~"calc(100% + 2px)";
                    position: absolute;
                    border: 1px solid @quaternary__color;
                    margin-left: -1px;
                    margin-right: -1px;
                    .lib-css(box-shadow, 0px 1px 4px rgba(0, 0, 0, 0.3));

                    .action.towishlist,
                    .wishlist.split.button {
                        display: none;
                    }

                    .action.tocompare {
                        .lib-compare-link();
                    }

                    .requisition-list-button.toggle {
                        margin-top: 5px;

                        span {
                            font-size: @font-size__s;
                        }
                    }
                }
            }
        }
    }

    .ias-load-more, .ias-load-prev {
        display: block !important;
        margin: 30px auto 15px;

        &:hover {
            display: block;
        }
    }

    .ias-spinner {
        margin: 30px 0;
        text-align: center;

        img {
            display: inline-block;
            max-width: 50px;
        }

        span {
            margin-top: @indent__base;
            display: block;
        }

    }
}

.product-item .price-box .price-container .price-including-tax .price {
    color: @secondary__color;
    font-weight: normal;
}

.product-item .product-item-details .product-item-actions .actions-primary .tocart {
    max-width: 100%;
}
