//
//  Variables
//  _____________________________________________
@aw-acp-mfp__background-color: #0b0b0b;
@aw-acp-mfp-container__background-color: @color-white;
@aw-acp-mfp-preloader__color: @color-gray80;
@aw-acp-price__color: #009900;
@aw-acp-mobile-close__color: @color-blue1;
@aw-acp-message-god__color: #27ae60;
@aw-acp-message-bad__color: #e74c3c;
@aw-acp-message-info__color: #2c3e50;
@aw-acp-message-god__background-color: #e8fff2;
@aw-acp-message-bad__background-color: #ffedeb;
@aw-acp-message-info__background-color: #fffae6;
@aw-acp-bar-gradient-top__color: #54f054;
@aw-acp-bar-gradient-bottom__color: #2bc253;
@aw-acp-mobile-header__border-color: @color-black;
//
//  Common
//  _____________________________________________

.aw-acp-popup-container {
    &.mfp-bg {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        overflow: hidden;
        width: 100%;
        height: 100%;
        background: @aw-acp-mfp__background-color;
        opacity: 0.8;
        .lib-css(transition, all 0.3s ease-out, 1);
    }

    &.mfp-wrap {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 101;
        width: 100%;
        height: 100%;
        outline: none !important;
        -webkit-backface-visibility: hidden;
    }

    .mfp-container {
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
        padding: 0 8px;
        width: 100%;
        height: 100%;
        text-align: center;

        &::before {
            display: inline-block;
            height: 100%;
            content: "";
            vertical-align: middle;
        }
    }

    &.mfp-align-top {
        .mfp-container {
            &::before {
                display: none;
            }
        }
    }

    .mfp-content {
        position: relative;
        z-index: 106;
        display: inline-block;
        margin: 0 auto;
        vertical-align: middle;
        text-align: left;
    }

    .mfp-ajax-holder, .mfp-inline-holder {
        .mfp-content {
            width: 100%;
            cursor: auto;
        }
    }

    &.mfp-ajax-cur {
        cursor: progress;
    }

    &.mfp-auto-cursor {
        .mfp-content {
            cursor: auto;
        }
    }

    .mfp-preloader {
        .lib-css(user-select, none, 1);
        position: absolute;
        top: 50%;
        right: 8px;
        left: 8px;
        z-index: 105;
        margin-top: -0.8em;
        width: auto;
        color: @aw-acp-mfp-preloader__color;
        text-align: center;

        a {
            color: @aw-acp-mfp-preloader__color;
            &:hover {
                color: @aw-acp-mfp-container__background-color;
            }
        }
    }

    .mfp-s-ready {
        .mfp-preloader {
            display: none;
        }
    }

    .mfp-s-error {
        .mfp-content {
            display: none;
        }
    }

    .aw-acp-popup-with-anim {
        opacity: 0;
        .lib-css(transition, all 0.2s ease-in-out, 1);
    }

    &--zoom-in {
        &.mfp-ready {
            .aw-acp-popup-with-anim {
                opacity: 1;
            }
        }

        &.mfp-removing {
            .aw-acp-popup-with-anim {
                opacity: 0;
            }

            &.mfp-bg {
                opacity: 0;
            }
        }
    }
}

.aw-acp-popup {
    position: relative;
    margin: 20px auto;
    padding: 15px;
    max-width: 800px;
    background: @aw-acp-mfp-container__background-color;
    box-sizing: border-box;

    * {
        box-sizing: border-box;
    }

    .swatch-option {
        box-sizing: content-box;
    }

    &__title,
    &__description,
    &__options-wrapper {
        margin: 0 0 20px;
    }

    &__mobile-title {
        .lib-bluebird-heading(h4);
        padding: 0 20px;
        margin: 10px 0;
    }

    &__form {
        .fieldset {
            > .field {
                &:not(.choice) {
                    > .label, > .control {
                        width: auto;
                        float: none;
                    }

                    > .label {
                        text-align: left;
                        box-sizing: content-box;
                        padding: 0;
                        margin: 0 0 5px;
                    }
                }
            }
        }
    }

    .products-grid .product-items:not(.widget-product-grid) .product-item .product-item-photo {
        margin: 0;
    }



    &__options-wrapper {
        .fieldset {
            margin-left: 2px;

            .legend {
                margin: 0 0 20px;
            }
        }
    }

    &__box-tocart-wrapper {
        .lib-vendor-prefix-display(flex);
        .lib-vendor-box-align(flex-end);
    }

    &__box-qty {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        margin: 0;
    }

    &__box-price {
        -webkit-box-flex: 1;
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        text-align: center;

        .price-container {
            .price {
                //font-size: 24px;
                color: @aw-acp-price__color;
            }
        }
    }

    &__mobile-close-wrapper {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        margin-left: 15px;
        line-height: 10px;

        &.headless {
            margin-left: 0;
            width: 100%;
        }

        .aw-acp-popup__mobile-close {
            &.action.primary {
                border: none;
                background: none;
                color: @aw-acp-mobile-close__color;
                width: 100%;
            }
        }
    }

    &__message {
        margin: 0 0 20px;
        padding: 13px 20px;
        border-radius: 4px;
        font-size: @base__font-size;
        line-height: 20px;

        .mq(m; {
            font-size: @base__font-size--desktop;
        });

        &--good {
           background: none;
           color: initial;
        }

        &--bad {
          background: none;
          color: initial;
        }

        &--info {
          background: none;
          color: initial;
        }
    }

    &__main {
        margin-bottom: 20px;
    }

    &__loader {
        .lib-vendor-prefix-display(flex);
        .lib-vendor-box-align(center);
        padding: 0 40px;
        height: 300px;

        &-bar {
            position: relative;
            display: block;
            overflow: hidden;
            margin: auto;
            max-width: 300px;
            width: 100%;
            height: 20px;
            border-radius: 10px;
            background: @aw-acp-bar-gradient-bottom__color -webkit-linear-gradient(top, @aw-acp-bar-gradient-top__color, @aw-acp-bar-gradient-bottom__color);
            background: @aw-acp-bar-gradient-bottom__color linear-gradient(180deg, @aw-acp-bar-gradient-top__color, @aw-acp-bar-gradient-bottom__color);
            box-shadow: inset 0 2px 9px hsla(0, 0%, 100%, .3), inset 0 -2px 6px rgba(0, 0, 0, .4);

            &::after {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 101;
                overflow: hidden;
                border-radius: 10px;
                background-image: -webkit-linear-gradient(135deg, hsla(0, 0%, 100%, .2) 25%, transparent 0, transparent 50%, hsla(0, 0%, 100%, .2) 0, hsla(0, 0%, 100%, .2) 75%, transparent 0, transparent);
                background-image: linear-gradient(-45deg, hsla(0, 0%, 100%, .2) 25%, transparent 0, transparent 50%, hsla(0, 0%, 100%, .2) 0, hsla(0, 0%, 100%, .2) 75%, transparent 0, transparent);
                background-size: 50px 50px;
                content: "";
                -webkit-animation: a 2s linear infinite;
                animation: a 2s linear infinite;
            }
        }
    }

    &__column--media {
        .lib-vendor-prefix-display(flex);
        .lib-vendor-prefix-flex-direction(column);
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
    }
    &__reviews-wrapper,
    &__image-wrapper {
        margin: 0;
        text-align: center;
    }
    &__reviews-wrapper {
        height: 20px;
        flex: 1 1 auto;

        .product-reviews-summary {
            display: block;

            .rating-summary, .reviews-actions {
                display: inline-block;
                vertical-align: middle;
            }
        }
    }

    &__image-wrapper {
        flex: 0 0 auto;
        position: relative;
    }

    &__image {
        max-width: 100%;
        max-height: 300px;
        height: auto;
    }

    &__actions {
        .lib-vendor-box-align(center);
    }

    &__close-wrapper {
        text-align: center;
    }

    &__columns {
        width: auto !important;

        .price-box {
            margin: 0;
        }
    }

    &__submit,
    &__close {
        font-size: 18px !important;
    }

    &__submit {
        width: 100%;
    }

    &__result {
        -webkit-align-self: center;
        -ms-flex-item-align: center;
        align-self: center;
        text-align: center;

        &-title {
            margin: 0 0 10px;
            font-size: @base__font-size--desktop;
        }

        &-price {
            margin: 0 0 10px;
            font-size: 24px;
        }

        &-items {
            margin: 0 0 10px;
        }

        &-view-cart {
            margin: 0;
        }

        /* Related */

        &__related {
            border-top: 1px solid @aw-acp-mfp-preloader__color;
            margin-top: 25px;
            margin-bottom: 0;

            .title {
                margin-top: 10px;
            }

            .products {
                margin: 0;

                .product-item-info {
                    margin: -10px;
                    padding: 9px;
                }
            }
        }

    }

}

@-webkit-keyframes a {
    0% {
        background-position: 0 0;
    }
    to {
        background-position: 50px 50px;
    }
}

@keyframes a {
    0% {
        background-position: 0 0;
    }
    to {
        background-position: 50px 50px;
    }
}

.mfp-hide {
    display: none !important;
}

/* Controls */
.input-text.qty {
    width: 54px;
    text-align: center;
}

.field.qty .label {
    font-weight: 600;
    margin: 8px 0;
    display: block;
}

.mq(xs; { .aw-acp-popup-container {
    .aw-acp-popup-with-anim {
        .lib-css(transform, scale(0.8), 1);
    }

    &--zoom-in {
        &.mfp-ready {
            .aw-acp-popup-with-anim {
                .lib-css(transform, scale(1), 1);
            }
        }
        &.mfp-removing {
            .aw-acp-popup-with-anim {
                .lib-css(transform, scale(0.8), 1);
            }
        }
    }
} .aw-acp-popup {
      &__mobile-header {
          display: none;
      }

      &__options-wrapper {
          max-height: 700px;
          overflow-y: auto;
          padding: 0 5px 0 0;
      }

      &__columns {
          .lib-vendor-prefix-display(flex);
      }

      &__column {
          &:first-child {
              -webkit-box-flex: 0;
              -webkit-flex: 0 0 auto;
              -ms-flex: 0 0 auto;
              flex: 0 0 auto;
              width: 250px;
          }

          &:nth-child(2) {
              -webkit-box-flex: 1;
              -webkit-flex: 1 1 auto;
              -ms-flex: 1 1 auto;
              flex: 1 1 auto;
              margin-left: 20px;
          }
      }
  } });

.mq(s; {
    .block.aw-acp-popup__related {
        margin-top: 20px;

        .block-title strong {
            font-size: 2rem;
        }
    }

    .products-grid {
    &.products-acp-related:not(.widget-product-grid) {
        .product-items {
            .lib-css(display, flex);
            .lib-css(align-items, stretch);
        }

        .product-item {
            width: 25% !important;

            &:nth-child(n+5) {
                display: none;
            }
        }

        .product-item-info {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .product-item-details {
            display: flex;
            flex-direction: column;

            .product-item-name {
                margin: 10px 12px;
                max-height: 40px;
                overflow: hidden;

                .product-item-link {
                    font-size: @font-size__s;
                }
            }
        }

        .product-item .price-box{
            margin: 10px 12px;

            .price-container {
                .price-including-tax .price {
                    font-size: 1.3rem;
                }
                .price-excluding-tax .price {
                    font-size: 1.5rem;
                }
            }
        }

        .actions-primary {
            margin-top: 10px;
            padding-top: 10px;
            border-top: 1px solid @border-color__base;
        }

        .action.primary {
            display: block;
            font-size: 1.2rem;
            background-color: #47bb0e;
            border: none;
            padding: 10px;
        }

        .action.tocart.primary {
            display: block;
            margin: 10px auto;
        }
    }
} });

.mq(- s; { .aw-acp-popup-container {
    .mfp-container {
        position: static;
        padding: 0;
        background: @aw-acp-mfp-container__background-color;

        &::before {
            display: none;
        }
    }

    .mfp-content {
        position: static;
    }
} .aw-acp-popup {
      position: static;
      margin: 0;

      &__mobile-header {
          position: fixed;
          top: 0;
          left: 0;
          z-index: 102;
          .lib-vendor-prefix-display(flex);
          .lib-vendor-box-align(center);
          -webkit-box-pack: justify;
          -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
          justify-content: space-between;
          overflow: hidden;
          padding: 0 7.5px;
          width: 100%;
          height: 40px;
          border-bottom: 1px solid @aw-acp-mobile-header__border-color;
          background: @aw-acp-mfp-container__background-color;
      }

      &__product-info {
          display: none;
      }

      &--result {
          .aw-acp-popup__message {
              -webkit-box-flex: 0;
              -webkit-flex: 0 0 auto;
              -ms-flex: 0 0 auto;
              flex: 0 0 auto;

              &--info {
                  display: none;
              }
          }

          .aw-acp-popup__columns {
              -webkit-box-flex: 0;
              -webkit-flex: 0 0 auto;
              -ms-flex: 0 0 auto;
              flex: 0 0 auto;
              margin: auto;
              padding-bottom: 20px;
          }

          .aw-acp-popup__column {
              &--media {
                  display: none;
              }
          }

      }

      &__main {
          left: 0;
          overflow-y: auto;
          -webkit-overflow-scrolling: touch;
          margin: 0;
          padding: 40px 15px 40px 15px;
          width: 100%;

          &.aw-acp-popup--result {
              .lib-vendor-prefix-display(flex);
              .lib-vendor-prefix-flex-direction(column);
          }
      }

      &__loader {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
      }

      &__column {
          &:first-child {
              margin-bottom: 20px;
          }

          &--media {
              .lib-vendor-prefix-flex-direction(column-reverse);

              &.small {
                  .aw-acp-popup__image-wrapper {
                      width: 50%;
                      margin: auto;
                  }
              }
          }
      }

      &__reviews-wrapper {
          text-align: left;
          margin-bottom: 10px;
      }

      &__close-wrapper {
          display: none;
      }

      &__submit-wrapper {
          position: fixed;
          bottom: 0;
          left: 0;
          width: 100%;
          z-index: 2;
      }

  } });

// Bluebird Day
.aw-acp-popup {
    padding-top: 45px;

    // smaller titles
    &__title {
        font-size: 2rem;
    }

    // hide info box
    &__message&__message--info {
        display: none;
    }

    // align prices
    &__box-price {
        .price-excluding-tax .price {
            margin-left: 20px;
        }
    }

    &__result-price {
        font-size: 2.4rem;
        color: @secondary__color;
        font-weight: 700;
    }

    &__loader {
        justify-content: center;
        text-align: center;
    }

    &__main {

        .aw-acp-popup__mobile-close-wrapper .aw-acp-popup__mobile-close.action.primary {
            position: absolute;
            display: block !important;
            right: 0;
            top: 0;
            font-size: 0;
            width: auto;

            &:after {
                .svg-delete-icon-blue();
                .svg-delete-icon-blue-dims();
                font-size: 1.4rem;
                content: '';
                display: block;
            }
        }

    }

    .action.primary {
        height: auto;
        display: block;
        margin: 10px auto;

        &[data-action="checkout"], &[data-action="submit"] {
            .btn-primary();

        }

        &[data-action="submit"] {
            position: relative;
            padding-right: 65px;
            padding-left: 25px;
            text-align: left;

            &:before {
                content: "";
                .svg-cart-icon-white();
                .svg-cart-icon-white-dims();
                display: block;
                position: absolute;
                right: 20px;
                top: 9px;
            }
        }
    }

    .fieldset-bundle-options {
        .field.qty {
            display: none;
        }
    }

}

// show more/less
.aw-acp-popup {
    .aw-acp-popup__description {
        display: none;

        &.active {
            display: block;
        }
    }

    .read-more {
        .read-more__content {
            height: 60px;
            overflow: hidden;
            position: relative;
            margin-bottom: 10px;
        }

        &:not(.read-more--active) {
            .read-more__content {
                &:after {
                    content: '';
                    display: block;
                    background-image: linear-gradient(to bottom, transparent, white);
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: -1px;
                    height: 15px;
                }
            }
        }

        .read-more__link--less {
            display: none;
        }
    }

    .read-more--active {
        .read-more__content {
            height: auto;
        }

        .read-more__link--more {
            display: none;
        }

        .read-more__link--less {
            display: block;
        }
    }

    .read-more__link {
        cursor: pointer;
        color: @secondary__color;
    }
}
