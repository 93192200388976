
//
//  Mini Search
//  ---------------------------------------------

.block-search {
    width: 100%;
    padding-left: 0;
    float: none;

    .mq(l; {
        min-width: 175px;
    });

    .mq(xl; {
        min-width: 300px;
    });

    .mq(- l; {
        .action.search {
            padding: 0 25px;

            > span {
                display: none;
            }
        }

        input {
            padding-right: 60px;
        }
    });

    input {
        background-color: @color-white;
        border: none;
        border-radius: 8px;
        box-shadow: @default__box-shadow;
        padding-right: 100px;
        font-size: @font-size__xl;
        font-family: @primary__font-family;

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: @text__color--muted !important;
        }
        &::-moz-placeholder { /* Firefox 19+ */
            color: @text__color--muted !important;
        }
        &:-ms-input-placeholder { /* IE 10+ */
            color: @text__color--muted !important;
        }
        &:-moz-placeholder { /* Firefox 18- */
            color: @text__color--muted !important;
        }

        &::-ms-clear {
            display: none;
            height: 0;
            width: 0;
        }

        &:not([disabled]){
            &:hover,
            &:active,
            &:focus {
                border: none;
                box-shadow: @dropdown__box-shadow;
            }
        }
    }

    button.action.search {
        .btn-square();
        .btn-primary();
        border: 1px solid @button-primary__background;
        bottom: 0;
        opacity: 1 !important;
        padding: 0;
        right: 0;
        top: 0;

        @media (max-width: 1023px) {
            height: 100%;
            padding: 12px;
            margin: 0;
        }

        > span {
            display: none;
        }

        &::before {
            .svg-search-icon-white();
            .svg-search-icon-white-dims();
            content: '';
        }
    }

    .control {
        border-top: 0;
    }

    .label {
        display: none;
    }
}

#minisearch-autocomplete-top-search {
    border: 1px solid #b9c4cd;
    background-color: white;
    padding: .5em;
    margin-top: 0;
}

.copyright{
    display: none !important;
}

