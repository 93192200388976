//
//  Banner
//  ---------------------------------------------

.banner-widget {
  .elementMaxWidth();
}

.banner-widget__image-wrapper {
  .mq(- l; {
    min-height: 200px;
  });
}

.page-section--top .page-section__inner {
  max-width: none;
  padding: 0;

    .banner-widget__inner {
        align-items: flex-end;
        background-position: center center;
        background-size: cover;
        box-sizing: border-box;
        display: flex;
        height: 100px; // Because IE11..
        margin-left: auto;
        margin-right: auto;
        max-width: calc(@layout__max-width - (@indent__base * 2));
        min-height: 430px;
        padding: 0 @indent__base;
        width: 100%;

        .mq(- l; {
            align-items: center;
            min-height: 200px;
        });
    }
    .banner-widget {
        p {
            font-family: Ubuntu,sans-serif;
            font-style: normal;
            font-weight: @font-weight__bold;
            font-size: 4rem;

            .mq(- m; {
                font-size: 3.4rem;
            });
        }
        strong {
            font-weight: @font-weight__bold;
            color: @secondary__color;
        }

        span {
            display: inline;
            background: @color-white;
            padding: @indent__s 14px
        }
    }
}

.banner-widget__buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    .mq(m; {
        margin-bottom: 24px;
    });
}

a.banner-widget__button {
    .lib-css(transition, all 0.3s 0s);
    background: rgba(255, 255, 255, 0.7);
    color: @primary__color;
    display: block;
    font-size: @font-size__l;
    margin: 0 12px @indent__s;
    min-width: calc(~'25% - 24px');
    padding: 8px @indent__base;
    text-align: center;

    .mq(m; {
        margin: 0 12px 24px;
    });

    &::after {
        .svg-long-arrow-right-icon-lightblue();
        .svg-long-arrow-right-icon-lightblue-dims();
        .lib-css(transform, none);
        .lib-css(transition, transform 0.3s 0s);
        content: '';
        display: inline-block;
        margin: 0 0 0 @indent__s;
        position: relative;
    }

    &:hover {
        background: rgba(255, 255, 255, 1);
        text-decoration: none;
        transform: translateY(-3px) scale(1.05);

        &::after {
            transform: translateX(5px);
        }
    }
}

.cms-index-index {
    .sections.nav-sections {
        display: none;
        margin-bottom: 0;
    }
}
