.navigation {
    .mq(l; {
        align-items: center;
        background-color: @navigation__background;
        display: flex;
        font-size: @font-size__l;
        font-family: @secondary__font-family;
        letter-spacing: @letter-spacing__negative;
    });

    ul.navigation-wrapper {
        .mq(l; {
            display: flex;
            gap: 15px;
            justify-content: space-evenly;
            padding: 0 @indent__sm;
            width: 100%;
        });

        .mq(xl; {
            gap: 25px;
        });

        li {
            &.level0 {
                .mq(l; {
                    margin: 0;
                    position: static;
                });

                &:not(.ui-menu-item) {
                    display: none;
                }

                &.parent,
                &.has-submenu {
                    &:hover {
                        > a.level-top {
                            &::after {
                                .mq(l; {
                                    transform: rotate(45deg);
                                    background-color: @color-white;
                                });
                            }
                        }
                        > .submenu {
                            .mq(l; {
                                display: flex;
                            });

                            &.default-menu__submenu,
                            &.full-width-menu__submenu {
                                > li {
                                    .mq(l; {
                                        width: 100%;
                                    });

                                    .default-menu__container,
                                    .full-width-menu__container {
                                        .mq(l; {
                                            columns: 3;
                                        });

                                        .mq(xl; {
                                            columns: 4;
                                        });

                                        > div:not(.default-menu__static):not(.full-width-menu__static) {
                                            .mq(l; {
                                                break-inside: avoid-column;
                                            });
                                        }

                                        .default-menu__static,
                                        .full-width-menu__static {
                                            .mq(l; {
                                                break-inside: avoid;
                                                width: 400%; // To cover 4 columns
                                            });
                                        }
                                    }
                                }
                            }

                            &:not(.default-menu__submenu):not(.full-width-menu__submenu) {
                                > li {
                                    .mq(l; {
                                        flex: 0 0 25%;
                                    });
                                }
                            }
                        }
                    }

                    > a.level-top {
                        .mq(l; {
                            align-items: center;
                            display: flex;
                            gap: @indent__s;
                            padding: 0;
                        });

                        .mq(xl; {
                            gap: @indent__sm;
                        });

                        &::after {
                            .mq(l; {
                                .svg-plus-icon();
                                .svg-plus-icon-dims();
                                background-color: @color-white;
                                border: 4px solid @color-white;
                                border-radius: 50%;
                                box-shadow: @input__box-shadow;
                                content: '';
                                display: block;
                                transition: transform .1s ease-in-out;
                            });
                        }
                    }
                }

                &.active {
                    > a.level-top {
                        .mq(l; {
                            border-bottom: none;
                            color: @color-black;
                        });
                    }
                }

                > a.level-top {
                    .mq(l; {
                        color: @primary__color;
                        font-size: @font-size__base;
                        font-weight: @base__font-weight;
                        line-height: 10.6rem;
                        padding: 0;
                    });

                    .mq(xl; {
                        font-size: @font-size__l;
                    });

                    &:hover {
                        .mq(l; {
                            color: @primary__color;
                        });
                    }
                }

                > ul.submenu {
                    .mq(l; {
                        border: none;
                        border-radius: 12px;
                        box-shadow: @default__box-shadow;
                        left: 50%;
                        max-width: @layout__max-width;
                        padding: 24px 32px 48px;
                        position: fixed;
                        transform: translateX(-50%);
                        word-break: break-word;
                        width: 100%;
                    });

                    > li {
                        &.ui-menu-item {
                            .mq(l; {
                                display: none;
                            });
                        }

                        &.active,
                        &.level1.active {
                            > a {
                                .mq(l; {
                                    background-color: @tertiary__color;
                                    border-left: none;
                                });
                            }
                        }

                        h2,
                        h3,
                        h4,
                        h5,
                        &.level1 {
                            .mq(l; {
                                margin: 0;
                            });

                            > a {
                                .mq(l; {
                                    color: @primary__color;
                                    font-size: @h2__font-size;
                                    margin: 0;
                                    padding: 24px @indent__sm;
                                });

                                &:hover {
                                    .mq(l; {
                                        background: none;
                                    });
                                }
                            }
                        }

                        // Actual desktop submenu
                        ul {
                            > li {
                                .mq(l; {
                                    display: block;
                                });

                                .default-menu__container {
                                    .mq(l; {
                                        column-gap: 40px;
                                    });
                                }

                                a {
                                    position: relative;

                                    .mq(l; {
                                        display: flex;
                                        align-items: center;
                                        border-radius: 8px;
                                        color: @primary__color;
                                        font-size: @h4__font-size;
                                        font-weight: 300;
                                        line-height: 1.2;
                                        padding: @indent__s @indent__xl @indent__s @indent__sm;
                                    });

                                    &.go-back {
                                        .mq(l; {
                                            display: none;
                                        });
                                    }

                                    &:not(.go-back)::after {
                                        .svg-arrow-blue-icon();
                                        .svg-arrow-blue-icon-dims();
                                        content: '';
                                        display: inline-block;

                                        .mq(l; {
                                            position: absolute;
                                            right: @indent__sm;
                                            top: @indent__s;
                                        });
                                    }

                                    &:hover {
                                        .mq(l; {
                                            background-color: @tertiary__color;
                                        });

                                        small {
                                            .mq(l; {
                                                color: @primary__color;
                                            });
                                        }

                                        img {
                                            .mq(l; {
                                                border-color: @tertiary__color;
                                            });
                                        }
                                    }

                                    small {
                                        .mq(l; {
                                            color: @quaternary__color;
                                            font-size: @font-size__m;
                                        });
                                    }

                                    img {
                                        align-self: flex-start;
                                        flex-shrink: 0;
                                        width: 48px;
                                        height: 48px;
                                        object-fit: cover;
                                        border-radius: 8px;
                                        border: 1px solid @quaternary__color;

                                        .mq(l; {
                                            width: 56px;
                                            height: 56px;
                                            margin-inline-end: @indent__sm;
                                        });
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
