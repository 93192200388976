.contact-block {
    width: 100%;
    max-width: @layout__max-width;
    padding: 0 @layout__width-xs-indent;
    margin-inline: auto;
    box-sizing: border-box;

    .mq(m; {
        display: flex;
        padding: 0 @layout-indent__width;
    });
}

.contact-block__column {
    display: flex;
    flex-direction: column;
    gap: @indent__xs;
    flex-shrink: 0;

    .mq(- m; {
        margin-block-end: @indent__xs;
    });
}

.contact-block__column--image {
    align-self: flex-end;
}

// Info block
.contact-block__info {
    display: flex;
    flex-direction: column;
    background: @panel__color--secondary;
    color: @text__color--primary;
    padding: calc(3 * @indent__sm);
    border-radius: @border-radius;

    .mq(- m; {
        padding: calc(1.5 * @indent__sm);
    });
}

.contact-block__info-caption {
    order: -1;
    margin-block-end: calc(4 * @indent__sm);
    opacity: .7;
    text-transform: uppercase;
    font-family: @secondary__font-family;
    font-size: @font-size__l;
    line-height: @heading__line-height;

    .mq(- m; {
        margin-block-end: calc(2 * @indent__sm);
    });
}

.contact-block__info-title {
    margin-block-start: 0;
    color: @text__color--primary;
}

.contact-block__info-text {
    > *:last-child {
        margin-block-end: 0;
    }
}

// Detail block
.contact-block__detail {
    container-type: inline-size;
    display: flex;
    align-items: center;
    align-self: flex-end;
    width: 100%;
    gap: calc(1.5 * @indent__sm);
    padding: calc(1.5 * @indent__sm);
    background: @panel__color;
    border-radius: @border-radius;

    .mq(- m; {
        gap: @indent__sm;
    });
}

.contact-block__icon {
    display: none;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: calc(5 * @indent__sm);
    height: calc(5 * @indent__sm);
    border-radius: @border-radius--md;
    background: @tertiary__color;

    .mq(- m; {
        width: calc(4 * @indent__sm);
        height: calc(4 * @indent__sm);
    });

    &::before {
        .mq(- m; {
            scale: .8;
        });
    }
}

.contact-block__icon--phone {
    &::before {
        content: '';
        .svg-phone-icon();
        .svg-phone-icon-dims();
    }
}

.contact-block__icon--email {
    &::before {
        content: '';
        .svg-mail-icon();
        .svg-mail-icon-dims();
    }
}

.contact-block__detail-content {
    display: flex;
    flex-direction: column;
}

.contact-block__detail-title:is(p) {
    margin-block: 0;
}

.contact-block__detail-link {
    font-size: @font-size__l;
    font-weight: 700;
    text-decoration: underline;
    text-underline-offset: .8rem;

    .mq(- m; {
        font-size: @font-size__m;
    });
}

/* If the container is larger than 700px */
@container (min-width: 350px) {
    .contact-block__icon {
        display: flex;
    }
}

// Form section
.contact-block__form {
    display: flex;
    flex-direction: column;
    gap: calc(3 * @indent__sm);
    padding: calc(2.5 * @indent__sm);
    background: @panel__color;
    border-radius: @border-radius;

    .mq(- m; {
        gap: calc(2 * @indent__sm);
        padding: calc(1.5 * @indent__sm);
    });

    .contact-page__form {
        margin-block-end: 0;
    }
}

// Image section
.contact-block__image {
    line-height: 0;

    .mq(- l; {
        display: none;
    });
}
