// Pagebuilder Button
a.pagebuilder-button-primary,
button.pagebuilder-button-primary,
div.pagebuilder-button-primary {
    .btn-primary();

    &::after {
        .svg-arrow-white-icon();
        .svg-arrow-white-icon-dims();
        content: '';
        display: block;
    }
}

a.pagebuilder-button-secondary,
button.pagebuilder-button-secondary,
div.pagebuilder-button-secondary {
    .btn-secondary();

    &::after {
        .svg-arrow-white-icon();
        .svg-arrow-white-icon-dims();
        content: '';
        display: block;
    }

    &:hover {
        &::after {
            .svg-arrow-blue-icon();
            .svg-arrow-blue-icon-dims();
        }
    }
}

a.pagebuilder-button-ghost,
button.pagebuilder-button-ghost,
div.pagebuilder-button-ghost {
    .btn(
        @button__background: @color-white,
        @button__color: @primary__color,
        @button__background--hover: @color-black,
        @button__color--hover: @color-white,
        @button__border: 1px solid @quaternary__color,
        @button__border--hover: 1px solid @color-white
    );
    margin: 0 10px 10px 0;
    vertical-align: middle;

    &::after {
        .svg-arrow-blue-icon();
        .svg-arrow-blue-icon-dims();
        content: '';
        display: block;
    }

    &:hover {
        &::after {
            .svg-arrow-white-icon();
            .svg-arrow-white-icon-dims();
        }
    }
}

// Headings
[data-content-type='text'] {
    p {
        margin-bottom: 2.4rem;
        line-height: 1.8;

        .mq(m; {
            margin-bottom: 3.2rem;
        });
    }
}

.pagebuilder-column {
    [data-content-type='products'] {
        .product-item {

            .product-item-details{

                .product-item-name {
                    margin: 15px 0 @indent__s;

                    a {
                        font-size: @font-size__xl;
                        font-family: @secondary__font-family;
                        color: @text__color;

                        &:hover {
                            text-decoration: none;
                            color: @secondary__color;
                        }
                    }
                }

                .product-item-actions {
                    display: block;

                    .actions-primary {
                        display: block;

                        .tocart {
                            .btn-cart();
                            .btn-responsive();
                            padding-left: 0;
                            padding-right: 0;
                            text-align: center;
                            max-width: 320px;
                        }

                        .tocart:before{
                            display: none;
                        }

                        .action.primary:not(.tocart) {
                            .btn-primary();
                            .btn-responsive();
                            max-width: 320px;
                        }
                    }

                    .actions-secondary{
                        display: none;
                    }
                }
            }

            .product-item-photo {
                display: block;
            }

            .product-reviews-summary {
                display: none;
            }

            .lib-product-price-box(catalog);

            // Bundle product prices
            .price-box {
                .price-to {
                    display: none;
                }

                .price-from {
                    .price-label {
                        order: 1;
                        color: @secondary__color;
                        font-weight: @font-weight__bold;

                        &:after {
                            content: ':';
                        }
                    }
                }
            }
        }
    }
}

[data-content-type='row'][data-appearance='contained'] {
    padding: 0 @indent__base;
}
