
//
//  Account
//  ---------------------------------------------

.account-dropdown {

    .lib-bluebird-dropdown(
        @dropdown__top: 60px,
        @dropdown-arrow__right: 39px,
        @dropdown__min-width: 340px,
        @dropdown-has-header: true,
        @dropdown-content__padding: 0
    );

    .account-dropdown__trigger {
        .btn();
        .btn-square();
        position: relative;

        &.active {
            display: flex;
        }

        &:before {
            content: '';
            display: inline-block;
            .svg-account-icon();
            .svg-account-icon-dims();
        }

        .text,
        &.active:after,
        &:after {
            display: none;
        }
    }

    .account-dropdown__check {
        display: none;
        position: absolute;
        top: -6px;
        right: -6px;
        align-items: center;
        justify-content: center;
        height: 20px;
        min-width: 20px;
        margin: 0;
        padding: 0;
        color: @text__color--primary;
        background-color: @secondary__color;
        border-radius: 50%;
        font-size: 1.3rem;
        font-weight: @font-weight__bold;

        .mq(- m; {
            top: -4px;
            right: -4px;
            height: 16px;
            min-width: 16px;
            font-size: 1.1rem;
        });

        &::after {
            content: '';
            .svg-check-icon-white();
            .svg-check-icon-white-dims();
        }
    }

    &.active {
        .account-dropdown__trigger {
            &:after {
                transform: rotate(180deg);
            }
        }
    }

    .account-dropdown__login {
        padding: 17px @indent__base;
    }

    .account-dropdown__btn {
        .btn-primary();
        .btn-responsive();
        margin-top: @indent__base;
    }

    .account-dropdown__remind {
        display: block;
        text-align: center;
        margin-top: @indent__s;
        font-size: @font-size__m;
        .lib-bluebird-link();
    }

    .account-dropdown__register {
        border-top: 1px solid @quaternary__color;
    }

    a.account-dropdown__register-url {
        display: block;
        padding: 17px @indent__base;
        text-align: center;
        color: @primary__color;
        font-family: @secondary__font-family;
        font-size: 2rem;
        font-weight: @font-weight__bold;

        span {
            display: block;
            font-family: @primary__font-family;
            color: @secondary__color;
            font-weight: normal;
        }

        &:hover {
            text-decoration: none;
            span {
                text-decoration: underline;
            }
        }
    }

    .header.links {
        li {
            display: block;
            padding: 0;
        }

        a {
            display: block;
            padding: 8px @indent__base;
        }
    }

    &.account-dropdown--logged-in {
        .account-dropdown__check {
            display: flex;
        }

        .dropdown__content {
            .dropdown__body {
                padding: @indent__s 0;
            }
        }
    }

    .mq(- m; {
        position: static;

        .dropdown__content {
            left: @indent__s;
            right: @indent__s;
            min-width: 0;
            text-align: left;

            &:before {
                right: 92px;
                top: -23px;
            }
        }
    });
}
