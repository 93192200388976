//
//  Settings
//  ---------------------------------------------






//
//  Checkout override Luma styles
//  ---------------------------------------------

.opc-progress-bar-item._active {
    &::before {
        background: @primary__color;
        border-color: @primary__color;
    }

    > span {
        &:before {
            background: @primary__color;
            border-color: @primary__color;
        }

        &:after {
            border-color: @primary__color;
        }
    }
}

.opc-wrapper {
    .shipping-address-item.selected-item {
        border-color: @primary__color;

        &:after {
            background: @primary__color;
        }
    }
}

.paypal-review-discount .block > .title strong,
.cart-discount .block > .title strong {
    color: @secondary__color;
}

.cart-totals .totals .mark,
.cart-totals .totals .amount {
    font-size: @font-size__m;

    .mq(m; {
        font-size: @font-size__l;
    });
}

.cart-totals .grand.excl + .grand.incl .mark,
.cart-totals .grand.excl + .grand.incl .amount,
.opc-block-summary .grand.excl + .grand.incl .mark,
.opc-block-summary .grand.excl + .grand.incl .amount {
    border-top: 0;
    font-size: @font-size__m;
    padding-top: 0;

    .mq(m; {
        font-size: @font-size__l;
    });

    strong {
        font-weight: 400;
    }
}

//
//  Checkout
//  ---------------------------------------------

.checkout-index-index {
    .prevent-clicking-minicart();

    .checkout-shipping-address {
        .field {
            .control._with-tooltip input {
                width: calc(~'100% - 36px');
            }
        }
    }

    .minicart-items {
        .subtotal {
            .lib-display-price(minicart-item);
        }
    }

    .opc-block-summary {
        .minicart-items-wrapper {
            max-height: 700px;
        }
        .product.options span span {
            color: @secondary__color;
            text-decoration: underline;
        }
        .product-item {
            .product-item-name-block {
                vertical-align: top;
            }

            .price {
                font-weight: @font-weight__bold;
            }
        }

        .items-in-cart > .title{
            &::after {
                top: 18px;
            }
        }
    }

    .checkout-container {
        margin-top: 40px;

        .mq(- m; {
            margin-top: 0;
        });
    }

    .authentication-wrapper {
        margin-top: 0;

        .mq(- m; {
            margin-top: 6px;
        });
    }

    .opc-estimated-wrapper {
        .minicart-wrapper {
            .mq(- m; {
                padding: 6px @indent__s 0 @indent__base;
            });
        }
    }

    .file-uploader {
        input[type="file"] {
            width: 100%;
        }
    }

    .field-tooltip {
        .label {
            > span {
                display: none;
            }
        }

        .field-tooltip-action {
            margin-top: 5px;
        }

        .field-tooltip-content {
            &:before,
            &:after {
                top: 17px;
            }

            .mq(- m; {
                right: -@indent__s;
                top: 40px;
                left: auto;

                &:before,
                &:after {
                    border: 10px solid transparent;
                    height: 0;
                    width: 0;
                    margin-top: -21px;
                    right: @indent__s;
                    left: auto;
                    top: 0;
                }

                &:before {
                    border-bottom-color: #666;
                }

                &:after {
                    border-bottom-color: #f4f4f4;
                    top: 1px;
                }
            });
        }
    }

    // Payment Methods
    .payment-methods {
        .buckaroo_magento2_ideal,
        .buckaroo_magento2_idealprocessing {
            .buckaroo_magento2_image_title {
                &::before {
                    @media screen and (max-width: 480px) {
                        background: url('../images/buckaroo/buckaroo_magento2_ideal_title.png') no-repeat;
                        float: left;
                        margin-right: 10px;
                        margin-top: -1px;
                        background-size: auto 25px;
                        width: 40px;
                        height: 30px;
                        content: ' ';
                    }
                }

                &::after {
                    background: url('../images/buckaroo/buckaroo_magento2_ideal_title.png') no-repeat;
                    float: right;
                    margin-right: 10px;
                    margin-top: -1px;
                    background-size: auto 25px;
                    width: 30px;
                    height: 25px;
                    content: ' ';
                }
            }
        }

        .buckaroo_magento2_creditcard,
        .buckaroo_magento2_creditcards {
            .buckaroo_magento2_image_title {
                &::before {
                    @media screen and (max-width: 480px) {
                        background: url('../images/buckaroo/buckaroo_magento2_creditcard_title.png') no-repeat;
                        margin-top: 0;
                        float: left;
                        margin-right: 10px;
                        background-size: auto 20px;
                        width: 40px;
                        height: 30px;
                        content: ' ';
                    }
                }

                &::after {
                    background: url('../images/buckaroo/buckaroo_magento2_creditcard_title.png') no-repeat;
                    margin-top: -1px;
                    float: right;
                    margin-right: 10px;
                    background-size: auto 22px;
                    width: 35px;
                    height: 25px;
                    content: ' ';
                }
            }
        }

        .buckaroo_magento2_paypal {
            .buckaroo_magento2_image_title {
                &::before {
                    @media screen and (max-width: 480px) {
                        background: url('../images/buckaroo/buckaroo_magento2_paypal_title.png') no-repeat;
                        float: left;
                        margin-right: 10px;
                        margin-top: -4px;
                        background-size: auto 30px;
                        width: 40px;
                        height: 30px;
                        content: ' ';
                    }
                }

                &::after {
                    background: url('../images/buckaroo/buckaroo_magento2_paypal_title.png') no-repeat;
                    float: right;
                    margin-right: 10px;
                    margin-top: -4px;
                    background-size: auto 30px;
                    width: 30px;
                    height: 30px;
                    content: ' ';
                }
            }
        }

        .buckaroo_magento2_mrcash {
            .buckaroo_magento2_image_title {
                &::before {
                    @media screen and (max-width: 480px) {
                        background: url('../images/buckaroo/buckaroo_magento2_mrcash_title.svg') no-repeat;
                        float: left;
                        margin-right: 10px;
                        margin-top: -4px;
                        background-size: auto 30px;
                        width: 40px;
                        height: 30px;
                        content: ' ';
                    }
                }

                &::after {
                    background: url('../images/buckaroo/buckaroo_magento2_mrcash_title.svg') no-repeat;
                    float: right;
                    margin-right: 10px;
                    margin-top: -4px;
                    background-size: auto 30px;
                    width: 40px;
                    height: 30px;
                    content: ' ';
                }
            }
        }

        label.buckaroo_magento2_image_title.label {
            position: relative;
            padding-left: 45px;
        }

        .buckaroo_magento2_image_title {
            &:after,
            &:before {
                position: absolute;
                left: 0;
                top: 0;
                background-size: contain;
                background-position: center top;
                width: 38px;
            }
        }
    }

    // Address Fields
    .field.street .field.additional {
        margin-top: 0;
        padding-left: @indent__s;
        width: 100px;
    }

    .field.street > .control {
        .lib-css(display, flex);
    }

    .field.street > .control [name*=".street.0"] {
        .lib-css(flex, 10 5 auto);
    }

    .field.street > .control [name*=".street.1"] {
        .lib-css(flex, 0 10 auto);
    }

    .field.street > .control [name*=".street.2"] {
        .lib-css(flex, 0 10 auto);
    }

    .fieldset.address {
        .input-text[value=""] {
            border-color: @border__color;
        }

        .input-text[aria-invalid="false"]:not([value=""]) {
            background-image: url('../svg/src/check-icon-green.svg');
            background-position: center right 10px;
            background-repeat: no-repeat;
            border-color: @success__color;
        }

        .input-text[aria-invalid="true"] {
            background-image: url('../svg/src/delete-icon-red.svg');
            background-position: center right 10px;
            background-repeat: no-repeat;
            border-color: @error__color;
        }
    }

    // Discount
    .form-discount {
        .mq(m; {
            max-width: none;
            .lib-css(display, flex);
            .lib-css(justify-content, space-between);

            .payment-option-inner {
                width: calc(~'100% - 230px');
            }

            .actions-toolbar {
                width: 220px;

                > .primary,
                > .primary .action {
                    width: 100%;
                }
            }
        });
    }


    .checkout-shipping-method {
        .actions-toolbar {
            .action.primary {
                .btn-primary();
            }
        }
    }

    .checkout-payment-method {
        .payment-method-content {
            > .actions-toolbar {
                > .primary {
                    .action.primary {
                        .btn-cart();
                    }
                }
            }
        }
    }

    .checkout-agreements-block .action-show {
        line-height: 1;
        color: @primary__color;

        &:focus {
            color: @primary__color;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    .experius_postcode_fieldset {
        strong {
            display: block;
            margin: 15px 0;
        }
    }
}

.form.form-shipping-address {
    #experius-postcode-fieldset {
        margin-left: 0;
    }

    #experius-postcode-fieldset .field[name*='experius_postcode_postcode'],
    #experius-postcode-fieldset .field[name*='experius_postcode_housenumber'],
    #experius-postcode-fieldset .field[name*='experius_postcode_housenumber_addition'] {
        width: auto;
    }

    #experius-postcode-fieldset .field {
        float: none;
        padding-right: 0;
    }
}





/**
Microdata (how they call it)
*/
@checkout-microdata__color: #3ea30c;

.checkout-index-index {
    .form-shipping-address .field.street .field .label {
        display: none;
    }

    .checkout-shipping-method {
        #label_carrier_bestway_tablerate {
            position: relative;
            .mq(- m; {
                padding-bottom: 50px;
            });

            &:after {
                display: block;
                content: attr(data-track-trace);
            }

            &:before {
                display: block;
                content: attr(data-most-chosen);
                color: @checkout-microdata__color;
                position: absolute;

                .mq(- m; {
                    left: @indent__s;
                    bottom: 28px;
                    height: 22px;
                });

                .mq(m; {
                    right: -250px;
                    top: 30px;
                });
            }
        }
    }

    .payment-methods {
        .buckaroo_magento2_ideal {
            position: relative;
            .checkout-payment-label-microdata {
                color: @checkout-microdata__color;
                float: right;
                margin-bottom: @indent__s;
            }
        }

        .actions-toolbar > .primary {
            .checkout-payment-text {
                text-align: right;
                float: right;
                display: block;
                margin: @indent__s 0;
            }
        }
    }

    /**
    * Store credit.
    */
    .checkout-payment-method .form-purchase-order {
        margin-top: 1em;
    }
}
