
//
//  Forms
//  ---------------------------------------------

input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: @text__color--muted;
}
input::-moz-placeholder { /* Firefox 19+ */
    color: @text__color--muted;
}
input:-ms-input-placeholder { /* IE 10+ */
    color: @text__color--muted;
}
input:-moz-placeholder { /* Firefox 18- */
    color: @text__color--muted;
}


//
//  Input
//  ---------------------------------------------

.lib-bluebird-input(
    @input__color: @input__color,
    @input__padding: @input__padding,
    @input__font-size: @input__font-size,
    @input__font-family: @input__font-family,
    @input__border: @input__border,
    @input__border-radius: @input__border-radius,
    @input__box-shadow: @input__box-shadow,
    @input__height: @input__height,
    @input__height-mobile: @input__height-mobile,
    @input__border--focus: @input__border--focus,
    @input__box-shadow--focus: @input__box-shadow--focus
) {
    .lib-css(color, @input__color);
    .lib-css(padding, @input__padding);
    .lib-css(font-size, @input__font-size);
    .lib-css(font-family, @input__font-family);
    .lib-css(border, @input__border);
    .lib-css(border-radius, @input__border-radius);
    .lib-css(box-shadow, @input__box-shadow);
    .lib-css(height, @input__height);

    @media (max-width: 767px) {
        .lib-css(height, @input__height-mobile);
    }

    &:focus {
        .lib-css(border, @input__border--focus);
        .lib-css(box-shadow, @input__box-shadow--focus);
    }

    &.readonly{
        opacity: 1;
        background: lightgrey;
        pointer-events: none;

        &:focus {
            .lib-css(border, @input__border);
            .lib-css(box-shadow, none);
        }
    }
}

.lib-bluebird-input-square() {
    .lib-bluebird-input(@input__border-radius: @input__border-radius--square);
}

.lib-bluebird-input-qty() {
    .lib-bluebird-input-square();
    text-align: center;
    padding: 0 @indent__s;
}
