.navigation {
    .mq(- l; {
        background-color: @primary__color;
        display: flex;
        flex-direction: column;
        gap: @indent__s;
        height: 100vh;
        justify-content: flex-start;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 28px @indent__base;
        position: fixed;
        top: 0;
        transform: translateX(100%);
        transition: transform 120ms ease-in-out;
        width: 100%;
        z-index: 100;
    });

    .mq(- m; {
        padding: 42px 0;
    });

    .navigation-wrapper {
        .mq(- l; {
            order: 2;
        });
    }

    .page-header__language {
        .mq(- m; {
            padding: 0 @indent__sm;
        });

        .mq(- l; {
            order: 3;
        });
    }

    .page-header__search {
        .mq(- m; {
            padding: 0 @indent__sm;
        });

        .mq(- l; {
            order: 1;
        });
    }

    &--active {
        transform: translateX(-@indent__sm);
    }

    > ul {
        .mq(- l; {
            .frame();
            background-color: @color-white;
            border-radius: 12px;
            left: 0;
            padding: @indent__m;
            position: relative;
            top: 0;
            transition: left 120ms ease-in-out;
        });

        .mq(- m; {
            margin-right: @indent__sm;
            margin-left: @indent__sm;
            width: ~"calc(100% - @{indent__l})";
        });

        &.subnavigation--active {
            .mq(- l; {
                left: ~"calc((100% + @{indent__l}) * -1)";
            });

            .mq(- m; {
                left: -100%;
            });

            + .page-header__language {
                .mq(- l; {
                    display: none;
                });
            }

            ul.active {
                .mq(- l; {
                    .frame();
                    background-color: @color-white;
                    border-radius: 12px;
                    display: block !important;
                    left: ~"calc(100% + @{indent__sm})";
                    margin-right: @indent__sm !important;
                    margin-left: @indent__sm !important;
                    padding: @indent__m !important;
                    position: absolute !important;
                    top: 0;
                    transition: left 120ms ease-in-out;
                    width: 100%;
                });
            }
        }

        > li.level0 {
            .mq(- l; {
                border: 0;
                display: list-item;
                position: static;
            });

            > a.level-top {
                .mq(- l; {
                    color: @primary__color;
                    font-family: Ubuntu, sans-serif;
                    font-size: @font-size__xl;
                    font-weight: @font-weight__regular;
                    padding: 0;
                    text-transform: none;
                });
            }

            ul {
                .mq(- l; {
                    border: none;
                    margin: @indent__sm 0;
                    overflow: visible !important;
                    position: static !important;
                });

                &.submenu {
                    > li {
                        .mq(- l; {
                            width: 100%;
                        });

                        &.ui-menu-item {
                            .mq(- l; {
                                display: none;
                            });
                        }

                        h4 {
                            .mq(- l; {
                                margin: 0 0 @indent__sm;
                            });

                            &.has-subitems {
                                a {
                                    &::after {
                                        .mq(- l; {
                                            .svg-arrow-next-icon();
                                            .svg-arrow-next-icon-dims();
                                            content: '';
                                        });
                                    }
                                }
                            }

                            a {
                                .mq(- l; {
                                    align-items: center;
                                    color: @primary__color;
                                    display: flex;
                                    gap: 8px;
                                    justify-content: space-between;
                                    font-family: Barlow, sans-serif;
                                    font-size: @font-size__m;
                                    padding: 0;
                                    text-transform: none;
                                });
                            }

                            + ul {
                                .mq(- l; {
                                    display: none;
                                });
                            }
                        }

                        ul {
                            margin: 0;
                            padding: 0;

                            li {
                                .mq(- l; {
                                    margin: 0 0 @indent__sm;
                                    width: 100%;
                                });

                                a {
                                    .mq(- l; {
                                        align-items: center;
                                        color: @primary__color;
                                        display: flex;
                                        flex-wrap: wrap;
                                        gap: 8px;
                                        font-family: Barlow, sans-serif;
                                        font-size: @font-size__m;
                                        padding: 0;
                                        text-transform: none;
                                    });

                                    &:not(.go-back)::after {
                                        .mq(- l; {
                                            margin-inline-start: auto;
                                        });
                                    }

                                    &.go-back {
                                        .mq(- l; {
                                            justify-content: flex-start;
                                            margin-bottom: @indent__m;
                                        });

                                        &::before {
                                            .mq(- l; {
                                                .svg-arrow-next-icon();
                                                .svg-arrow-next-icon-dims();
                                                content: '';
                                                transform: rotate(180deg);
                                            });
                                        }
                                    }

                                    small {
                                        .mq(- l; {
                                            color: @quaternary__color;
                                            flex: 0 0 100%;
                                            order: 3;
                                        });
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.menu-trigger__link {
    .btn-primary();
    .btn-square();

    .mq(l; {
        display: none;
    });

    &::before {
        .svg-menu-icon-white();
        .svg-menu-icon-white-dims();
        content: '';
        display: inline-block;
    }

    span {
        display: none;
    }
}

.navigation--open .menu-trigger__link {
    .btn-diap();
    .btn-square();
    z-index: 1000;

    &::before {
        .svg-cross-icon();
        .svg-cross-icon-dims();
    }
}
