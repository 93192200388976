
//
//  Buttons
//  ---------------------------------------------

.btn,
button.btn,
a.btn {
    .btn();
}

.btn-primary,
button.btn-primary,
a.btn-primary {
    .btn-primary();
}

.btn-secondary,
button.btn-secondary,
a.btn-secondary {
    .btn-secondary();
}

.btn-success,
button.btn-success,
a.btn-success {
    .btn-primary();
}

.btn-alt,
button.btn-alt,
a.btn-alt {
    .btn-alt();
}

.btn-responsive,
button.btn-responsive,
a.btn-responsive {
    .btn-responsive();
}

.action.primary,
a.action.primary,
button.action.primary,
.sidebar .action.tocart {
    .btn-primary();
}

.btn-diap,
button.btn-diap,
a.btn-diap {
    .btn-diap();
}

.btn-square,
button.btn-square,
a.btn-square {
    .btn-square();
}
