
//
//  Account
//  ---------------------------------------------

.mobile-search-icon {
    display: block;

    &:before {
        content: '';
        .svg-search-icon();
        .svg-search-icon-dims();
        display: block;
        margin: 0 auto 2px;
    }

    &:hover {
        text-decoration: none;
    }
}

.mq(- l; {
    .block-search {
        box-sizing: border-box;
        margin: 0;
        padding-right: 65px;
        position: relative;

        .control {
            input {
                position: static;
                margin: 0;
            }
        }

        .form.minisearch {
            position: relative;
        }

        .action.search {
            position: absolute;
            display: block;
            box-shadow: none;
            height: 40px;
            padding: 0 30px;
            top: @indent__s;
            right: @indent__s;

            &:after {
                right: 18px;
                top: 8px;
            }
        }

        &.block-search--active {
            display: block;
        }
    }
});
