.product-block-widget {
    margin: @indent__base 0 30px;

    &__tile {
        border: solid 1px transparent;
    }

    .widget.block {
        margin-bottom: 0;
    }

    .product-block-widget__link {
        &:hover {
            text-decoration: none;
        }

        .product-block-widget__text {
            color: @color-black_dark;
        }
    }

    .product-block-widget__title {
        .lib-bluebird-heading(h4);
        display: block;
        text-align: center;
        word-wrap: break-word;
        font-size: 1.5rem;
    }

    .product-block-widget__image {
        margin: 0;
        width: 100%;
        display: block;
    }

    .product-block-widget__text {
        margin-bottom: @indent__base;
        border-top: 0;
        padding: 32px 23px;
        background: @color-white;
        display: flex;
        flex-direction: column;

        p:last-of-type {
            margin-bottom: 2rem;
        }

        ul{
            padding: 0;

            li{
                list-style-type: none;
                margin-bottom: 0.5rem;

                a{
                    font-size: @font-size__xl;
                    color: @text__color;

                    &:hover{
                        color: @secondary__color;
                    }
                }
            }
        }

    }

    .action.primary {
        .btn-primary();

        &::after {
            .svg-arrow-white-icon();
            .svg-arrow-white-icon-dims();
            content: '';
            display: block;
        }
    }

    .product-block-widget__view-all-link {
        margin-top: auto;
    }
}

.product-block-widget--contact{
    .product-block-widget {
        .product-block-widget__text {
            strong {
                font-size: @font-size__xl;
                font-weight: @font-weight__bold;
                color: @primary__color;
            }

            a {
                color: @secondary__color;
            }
        }
    }
}
