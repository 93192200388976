
//
//  Page Main
//  ---------------------------------------------

.page-main {
    p {
        margin-bottom: 2.4rem;

        .mq(m; {
            margin-bottom: 3.2rem;
        });
    }

    .block {
        margin-bottom: 0;
    }
}

.has-page-sections {
    .page-main {
        max-width: none;
        padding-left: 0;
        padding-right: 0;
    }
}

.page-main__inner,
.page-section__inner {
    .outer-container();
}
