//
//  Subcategory
//  ---------------------------------------------

.subcategory__title {
    margin-block: 32px;

    h1 {
        margin: 0;
    }
}

.subcategory__container {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 1400px;
    padding-left: 20px;
    padding-right: 20px;
    width: auto;
}

.subcategory__wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.subcategory-item {
    .lib-frame(1px);
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: ~"calc((100% / 1) - ((8px * (1 - 1))) / 1)";
    background-color: @color-white;
    padding: 8px;
    border: 1px solid @color-white;
    border-radius: 8px;
    text-decoration: none;
    transition: box-shadow .2s ease-in-out, border-color .2s ease-in-out;

    &:hover,
    &:focus-visible {
        text-decoration: none;
        box-shadow: 0 3px 10px rgba(0, 0, 0, .3);
        border-color: @primary__color;
    }
}

.subcategory-item__image-wrapper {
    order: -1;
    aspect-ratio: 370/160;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.subcategory-item__image {
    height: 100%;
    width: auto;
    object-fit: contain;
    background-color: white;
}

.subcategory-item__name {
    position: relative;
    margin: 0 0 8px;
    padding-inline: 8px;
}

@media screen and (min-width: 768px) {
    .subcategory__title {
        margin-block: 48px;
    }

    .subcategory-item {
        width: ~"calc((100% / 3) - ((8px * (3 - 1))) / 3)";
    }

    .subcategory-item__name {
        margin: 0 0 16px;
        padding-inline: 16px;
    }
}

@media screen and (min-width: 1024px) {
    .subcategory-item {
        width: ~"calc((100% / 4) - ((8px * (4 - 1))) / 4)";
    }
}
